:root {
  --color-orange: #ed9c2e;
  --color-orange-hover: #df7e11;
  --color-unavailable: #433b65;
  --color-text: #000000;
  --color-text-light: #273d52;
  --color-text-lighter: #939ea8;
  --color-white: #ffffff;
  --color-text-secondary: var(--color-white);
  --color-body-background-color: #f6f7f9;
  --color-background-menu: #1b1d23;
  --color-bg-light-grey: #f9f9f9;
  --color-text-dark-grey: #2d3434;
  --color-text-darker: #333333;
  --color-bg-dark-grey-light: #22262f;
  --color-background-light-blue: #eff6f9;
  --color-text-light-blue: #ceebf2;
  --color-success-lighter: #b1e4d6;
  --color-subtitle-dark: #a1cbd5;
  --color-subtitle-lighter: #97a0aa;
  --color-bluish-grey: #273d52;
  --color-green: #00b28e;
  --color-blue: #1cadee;
  --color-dark-blue: #193550;
  --color-water-green: #29b6c5;
  --color-red: #eb4646;
  --color-border: #cccccc;
  --color-background-sub-item: #f4f7f8;
  --color-menu-filter-background-color: #fff;
  --color-success-dark-20: #00997a;
  --color-success-dark-40: #007f65;
  --color-warning-light-20: #ffd897;
  --color-warning-dark-20: #e78f27;
  --color-warning-dark-40: #dd8318;
  --color-light-grey: #eceeee;
  --color-grey: #9da7a9;
  --color-default-texts: #707070;
  --color-dark-grey: #717678;
  --color-greenish-grey: #4a5c60;
  --color-ni-icon: #202330;
  --font-family: var(--font-family-theme);
  --font-family-bold: var(--font-family-theme-bold);
  --font-family-extra-light: var(--font-family-theme-extra-light);
  --color-header-gradient-start: var(--color-bluish-grey);
  --color-header-gradient-end: #4f708a;
  --color-header-input-border: #3e5a72;
  --button-background: var(--color-body-background-color);
  --button-background-focus: var(--color-neutral-light-80);
  --button-background-active: var(--color-neutral-light-60);
  --button-color: var(--color-header-gradient-start);
  --button-selected-background: var(--color-primary-dark-40);
  --button-selected-color: var(--color-white);
  --tab-header-color: var(--color-header-gradient-start);
  --tab-header-bg-color: var(--color-neutral-light-98);
  --tab-bg-color: var(--color-white);
  --badge-dark-red: #9a2f2f;
  --badge-blue: var(--color-blue);
  --badge-red: #ff756e;
  --badge-pink: #ff6969;
  --badge-primary: var(--color-text-blue);
  --badge-success: var(--color-success);
  --badge-warning: var(--color-warning);
  --badge-danger: var(--color-danger);
  --badge-unavailable: var(--color-unavailable);
  --badge-table: var(--card-off);
  --badge-table-text: var(--color-header-gradient-start);
  --badge-dark-info: var(--color-bluish-grey);
  --badge-grey: var(--color-grey);
  --card-off: #f5f5f5;
  --card-active: var(--color-tertiary);
  --card-primary: var(--color-text-blue);
  --card-success: var(--color-success);
  --card-warning: var(--color-warning);
  --card-danger: var(--color-danger);
  --card-unavailable: var(--color-unavailable);
  --kds-card-title-background-color: var(--color-bg-light-grey);
  --kds-card-background-color: var(--color-white);
  --kds-card-header-progress-background-color: rgba(0, 0, 0, 0.2);
  --color-toolbar-color-title: #273d52;
  --color-pipe-background-color: #e5e5e5;
  --collapsible-widget-header-color: #2fbecc;
  --collapsible-widget-header-focus-color: var(--color-secondary-dark-60);
  --collapsible-widget-divider-color: #dfebf0;
  --footer-bg: var(--color-neutral-light-98);
  --footer-text: var(--color-header-gradient-start);
  --footer-value: #4bbdc9;
  --popover-dark-title: var(--collapsible-widget-header-color);
  --popover-dark-subtitle: var(--color-subtitle-dark);
  --color-checkbox-group-border-input: var(--color-water-green);
  --color-checkbox-menu-label: #c4cbd2;
  --color-border-checkbox-menu-item: #3d566d;
  --backgroud-color-seleted-item: #1e3449;
  --progress-bar-background: var(--card-off);
  --progress-bar-color-default: var(--collapsible-widget-header-color);
  --progress-bar-box-shadow-10: rgba(0, 0, 0, 0.1);
  --progress-bar-box-shadow-15: rgba(0, 0, 0, 0.15);
  --progress-bar-box-shadow-20: rgba(0, 0, 0, 0.2);
  --technical-detail-progress-bar: rgba(47, 190, 204, 0.2);
  --percentual-badge-color-light-green: #50e3c2;
  --table-badge-popover-backgroud: var(--color-greenish-grey);
  --amount-received-light-blue: #2fbecc;
  --amount-received-grey: #2d3434;
  --slider-background: var(--color-success);
  --slider-background-light: var(--color-success-lighter);
  --average-occupancy-red: #ff756e;
  --average-occupancy-green: #50e3c2;
  --average-occupancy-black: #333333;
  --inventory-view-red: #e4463e;
  --inventory-view-yellow: #f5a623;
  --inventory-view-black: #1a1a1a;
  --consumption-card-tab-background: #fbfbfb;
  --consumption-card-search-bottom-line: #eaecef;
  --consumption-card-search-back: #c3c3c3;
  --progress-circle-background: #cfe4e9;
  --progress-circle-color-default: var(--collapsible-widget-header-color);
  --sales-amount-yellow: #f5a623;
  --divider-fiscal: #d5e2e4;
  --send-file: var(--color-water-green);
  --select-file-background: #cfe4e9;
  --select-file-color: #929da0;
  --color-divider: #dfebf0;
  --start-upload: var(--color-white);
  --divider-fiscal-bar: var(--divider-fiscal);
  --send-file-button: var(--send-file);
  --select-file-button: var(--select-file-background);
  --select-file-button-color: var(--select-file-color);
  --color-welcome-login: #d69a47;
  --welcome-login: #d85959;
  --color-bg-company-card-header: var(--color-bg-light-grey);
  --ni-color-button-group-border-selected: var(--color-text-blue);
  --ni-color-button-group-background-selected: var(--color-text-blue);
  --ni-color-button-group-border-hover: #097b98;
  --ni-color-button-group-background-hover: #097b98;
  --customer-list-border-color: #b6bdbf1f;
  --customer-list-background-color: #f3f3f3;
  --user-name-color: #1d2426;
  --shadow-degrade-transparent-0: rgba(246, 247, 249, 0);
  --shadow-degrade-transparent-0-8: rgba(246, 247, 249, 0.8);
  --button-shadow: var(--progress-bar-box-shadow-10);
  --background-content: #f6f6f6;
  --scrollbar-thumb-color: rgba(0, 0, 0, 0.3);
  --keyboard-pin-ripple-active: #ededed;
  --dashboard-select-button-font-color: var(--color-dark-grey);
  --report-table: #d3d3d3;
  --color-table-box-shadow-header: var(--color-tertiary);
  --color-table-color-header: var(--color-text-dark);
  --customer-list-sparse: #4a5055;
  --customer-list-guest: var(--color-dark-blue);
  --customer-list-event: #697e5c;
  --customer-list-card: #514762;
  --customer-list-person: #f39022;
  --background-color-toolbar-menu-right: var(--color-white);
  --background-color-ni-popover: var(--color-white);
  --color-icon-table-header: var(--color-text-blue);
  --color-text-empty-content: #bdbdbd;
  --background-color-pagination: var(--color-white);
  --color-text-pagination-active: var(--color-white);
  --background-color-pagination-active: var(--color-tertiary);
  --color-text-pagination: #273d52;
  --color-pagination-icon-disabled: #97a0aa;
  --background-kitchen-order-list-bottom: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 92%
  );
  --customer-border-color-input: #b6bdbf;
  --switch-color-label: var(--color-greenish-grey);
  --background-color-container: var(--color-white);
  --color-background-preview-image: #f4f7f8;
  --color-background-calendar: var(--color-dark-grey);
  --color-background-calendar-disabled: #b6bdbf;
  --color-background-blue-fixed: #193550;
  --color-scrollbar: rgba(31, 61, 82, 0.5);
  --title-change-password: #4a5c60;
  --color-background-divider: #c8c8c8;
  --background-color-text-more-items: var(--color-white);
}

.d-flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media screen and (max-width: 480px) {
  .po-visible-sm,
  [class*=" po-visible-sm-"],
  [class*=po-visible-sm-] {
    display: block !important;
  }
  .po-hidden-sm {
    display: none !important;
  }
}
po-dropdown .po-dropdown:focus,
po-dropdown .po-dropdown-button:focus {
  outline: none;
}
po-dropdown.no-border .po-dropdown-button {
  border: none;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
}
po-dropdown.small .po-dropdown-button {
  padding-top: 4px;
  padding-bottom: 4px;
  height: auto;
}
po-dropdown.small .po-dropdown-button .po-dropdown-icon {
  top: 4px;
}

.po-menu-filter {
  background-color: var(--color-menu-filter-background-color);
  color: var(--color-text-light);
}

.po-menu-sub-items {
  background-color: var(--color-menu-background-color-sub-items);
}

.profile .po-popover {
  min-width: 350px;
}

po-container.no-overflow .po-container {
  overflow-y: unset;
}

.po-page {
  background: white;
}

.po-wrapper .po-page-header {
  border-bottom: 1px solid #d3d3d3;
}

.po-page-header {
  padding: 16px 70px 16px 70px;
}

.po-wrapper .po-page .po-page-content {
  padding: 20px 70px;
}

.po-page-header-title {
  font-family: var(--font-family-theme), sans-serif;
}

.po-select-button {
  height: 14px;
  line-height: 14px;
  font-size: 14px;
}

.po-input,
.po-rich-text-body,
.po-select-button {
  box-shadow: inset 0 1px 8px 0 #fff;
}

.exercise-container .po-input {
  padding: 0 2px;
}

.plan-tab .po-field-title {
  font-weight: 700;
}

.po-input {
  height: 35px;
}

.input-time-width input {
  width: 4rem;
}

.btn-substitution-primary .po-button {
  --color-button-border: var(--color-button-substitution-primary);
  --color-button-background-color: var(--color-button-substitution-primary);
  --color-button-color: var(--color-white);
}
.btn-substitution-primary .po-button:hover {
  --color-button-border-hover: var(--color-button-substitution-primary);
  --color-button-background-color-hover: var(--color-button-substitution-primary);
  --color-button-color-hover: var(--color-white);
  opacity: 0.5;
}

.btn-substitution-secondary .po-button {
  --color-button-border: var(--color-button-substitution-secondary);
  --color-button-background-color: var(--color-button-substitution-secondary);
  --color-button-color: var(--color-white);
}
.btn-substitution-secondary .po-button:hover {
  --color-button-border-hover: var(--color-button-substitution-secondary);
  --color-button-background-color-hover: var(--color-button-substitution-secondary);
  --color-button-color-hover: var(--color-white);
  opacity: 0.5;
}

.input-time {
  margin: 8px 0;
}
.input-time[readonly] {
  background: var(--color-input-border-text-disabled);
}

.input-sum {
  padding: 0;
}
.input-sum .po-field-container {
  background: var(--color-background-gray);
  padding: 4px 4px;
  border-radius: 2px;
  border: 0.1px solid #9a9a9a;
}
.input-sum .po-field-container .po-field-container-content {
  padding: 0;
}
.input-sum .po-field-container .po-field-container-content .po-input {
  background: var(--color-background-gray);
  box-shadow: none;
  border: var(--color-background-gray);
  padding: 0;
  color: black;
  font-weight: 600;
  font-size: 1.1rem;
}
.input-sum .po-field-container .po-field-container-content .po-input:focus {
  border: none !important;
  outline-width: 0;
}

.input-time {
  margin: 8px 0;
}
.input-time[readonly] {
  background: var(--color-input-border-text-disabled);
}

.input-sum {
  padding: 0;
}
.input-sum .po-field-container {
  background: var(--color-background-gray);
  padding: 4px 4px;
  border-radius: 2px;
  border: 0.1px solid #9a9a9a;
}
.input-sum .po-field-container .po-field-container-content {
  padding: 0;
}
.input-sum .po-field-container .po-field-container-content .po-input {
  background: var(--color-background-gray);
  box-shadow: none;
  border: var(--color-background-gray);
  padding: 0;
  color: black;
  font-weight: 600;
  font-size: 1.1rem;
}
.input-sum .po-field-container .po-field-container-content .po-input:focus {
  border: none !important;
  outline-width: 0;
}

.po-menu-item {
  min-height: 4.5em;
}

div + .new-table,
po-table + .new-table {
  width: 100%;
  --font-size-default: 12px;
  --font-family: var(--font-family-bold);
  --background-color-headline: #f1f1f1;
}
div + .new-table .po-table tbody tr td,
po-table + .new-table .po-table tbody tr td {
  border-bottom: 1px solid var(--color-divider);
}
div + .new-table .po-table,
po-table + .new-table .po-table {
  color: #000000;
  font-size: 12px;
}
div + .new-table .po-table thead,
po-table + .new-table .po-table thead {
  position: sticky;
  top: 0;
  z-index: 20;
}
div + .new-table .po-table .po-table-header,
po-table + .new-table .po-table .po-table-header {
  background: #f1f1f1;
  border-bottom: none;
  height: 65px;
}
div + .new-table .po-table .po-table-header th:nth-child(1),
div + .new-table .po-table .po-table-header th:nth-child(2),
po-table + .new-table .po-table .po-table-header th:nth-child(1),
po-table + .new-table .po-table .po-table-header th:nth-child(2) {
  font-size: 18px;
  line-height: 100%;
}
div + .new-table .po-table .po-table-column-cell,
po-table + .new-table .po-table .po-table-column-cell {
  color: var(--color-text-gray);
  font-family: var(--font-family-theme);
}
div + .new-table .po-table .po-table-row,
po-table + .new-table .po-table .po-table-row {
  height: 60px;
}
div + .new-table .po-table-main-container,
po-table + .new-table .po-table-main-container {
  max-height: 470px;
  overflow: auto;
}

.table-v2 {
  width: 100%;
}
.table-v2 .table-column-item-blue {
  color: #ffffff;
  font-weight: bold;
  background: transparent linear-gradient(180deg, #126df7 0%, #1c95ee 100%);
  border-radius: 20px;
  padding: 2px 12px;
  opacity: 1;
  display: inline-flex;
  text-align: center;
}
.table-v2 .po-table {
  color: #000000;
}
.table-v2 .po-table thead {
  position: sticky;
  top: 0;
}
.table-v2 .po-table .po-table-header {
  border-bottom: none;
  height: 45px;
  font-size: 12px;
}
.table-v2 .po-table .po-table-row {
  height: 60px;
}
.table-v2 .po-table-main-container {
  overflow: auto;
}

.table-hide-header {
  min-height: unset;
}
.table-hide-header .po-table-header {
  display: none;
}

.icon-right-button {
  border-radius: 100%;
  background: #126df7;
  color: #fff;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
}

.table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: gray;
}

.po-table:has(.po-table-column-detail-toggle) {
  background: var(--background-color-headline);
}

.po-table tbody tr td {
  font-size: 12px;
}
@media screen and (max-width: 1366px) {
  .po-table tbody tr td {
    font-family: var(--font-family-theme), sans-serif;
    font-size: 12px;
    line-height: 16px;
  }
}

.po-table thead tr th {
  font-size: 12px;
  line-height: 14px;
}

.po-table .po-table-column-cell:has(po-button) {
  white-space: unset;
}
.po-table .po-table-column-cell {
  white-space: initial;
}

.po-table-header-flex {
  gap: 5px;
}

.po-table-header-icon-ascending, .po-table-header-icon-descending, .po-table-header-icon-unselected {
  width: 15px;
}

po-table .po-table-single-action {
  text-decoration: unset !important;
}

.po-rich-text-body:focus-visible {
  outline: unset;
  border: 2px solid #b6bdbf;
}

.po-menu-item-link:focus > .po-menu-item:not(.po-menu-item-no-data),
.po-menu-item:not(.po-menu-item-no-data):is(:focus, :focus-visible) {
  outline: unset;
}

.po-input,
.po-multiselect-input, select, .p-dropdown {
  border: 1px solid #b6bdbf;
}
.po-input:hover,
.po-multiselect-input:hover, select:hover, .p-dropdown:hover {
  background-color: unset;
  border: 1px solid #b6bdbf;
}
.po-input:focus,
.po-multiselect-input:focus, select:focus, .p-dropdown:focus {
  border: 2px solid #b6bdbf;
  outline: unset;
}

.po-multiselect-input:hover:not(.po-multiselect-input-disabled) {
  background-color: unset;
  border: 2px solid #b6bdbf;
}

.po-checkbox-outline:is(:focus, :focus-visible) {
  outline: unset;
}

.po-button-link:hover {
  background-color: unset;
}

.po-button:enabled:active, .po-button:focus, .po-button:focus-visible, select:focus {
  outline: unset;
}

select:hover, .po-textarea:hover {
  background-color: unset;
}

select {
  padding: 8px;
}

.po-combo-input-focus,
.po-combo-input:focus {
  outline: unset;
}

.po-combo-container-content span:hover.po-field-icon.po-icon.po-icon-arrow-down.po-icon-input,
.po-combo-input-focus:hover,
.po-combo-input:focus:hover,
.po-combo-input:hover:not(:disabled),
.po-field-icon-container-right .po-field-icon.po-icon.po-combo-background-arrow-up.po-icon-arrow-up {
  background-color: transparent;
}

.po-button[p-kind=secondary]:hover {
  background-color: unset;
}

.to-refresh {
  cursor: pointer;
  border: 0;
  background-color: initial;
}
.to-refresh:focus {
  animation: rotation 1s ease-in-out 0s;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.new-button-group .po-button-group-container po-button .po-button {
  padding: 0 35px;
  font-size: 18px;
  height: 44px;
  font-family: "Titillium Web", serif;
}
.new-button-group .po-button-group-container po-button:first-child .po-button {
  border-top-left-radius: 53px;
  border-bottom-left-radius: 53px;
}
.new-button-group .po-button-group-container po-button:last-child .po-button {
  border-top-right-radius: 53px;
  border-bottom-right-radius: 53px;
}
.new-button-group .po-button-group-container .po-button-group.po-button-group-button-selected .po-button {
  font-family: "Titillium Web Bold", serif;
}

.popover-custom-width .po-popover-content {
  width: 630px;
}

.container-badge-flag {
  display: inline-flex;
  align-items: center;
  background-color: #FFB400;
  justify-content: center;
  border-radius: 4px;
  padding: 4px 10px;
  width: auto;
}
.container-badge-flag .container-badge-flag-body {
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.container-badge-flag .container-badge-flag-body i {
  height: 25px;
  width: 25px;
  font-family: Titillium Web Bold, serif;
  font-weight: bold;
  margin-right: 2px;
}
.container-badge-flag .container-badge-flag-body span {
  font-family: var(--font-family-bold);
  font-size: 13px;
  color: #fff;
  line-height: 100%;
}

body {
  height: auto;
  min-height: 100%;
  background-color: var(--color-body-background-color);
  color: var(--color-body-text-color);
}

a,
button,
img {
  touch-action: manipulation;
}

po-select.po-select-clear .po-select-button,
po-select.po-select-clear .po-select-button:hover,
po-select.po-select-clear .po-select-button:active,
po-select.po-select-clear .po-select-button:focus {
  background-color: transparent !important;
  border: none !important;
  color: var(--color-primary-dark-40) !important;
  box-shadow: none;
  text-align: right;
}
po-select.po-select-clear .po-select-button {
  font-size: 0.9em;
}
po-select.po-select-clear .po-select-content {
  margin-top: 0;
}
po-select.po-select-clear .po-field-container-content {
  max-width: 250px;
  padding: 0;
}

.po-text-right {
  text-align: right;
}

.po-text-xlarge-light {
  font-size: 20px;
  font-family: "NunitoSans-ExtraLight", sans-serif;
  font-weight: lighter;
  text-align: center;
}

.po-w-100 {
  width: 100%;
}

.po-h-100 {
  height: 100%;
}

.bold {
  font-weight: bold;
}

.regular {
  font-weight: 400;
}

.po-text-color-light {
  color: var(--color-text-light);
}

.po-text-color-lighter {
  color: var(--color-text-lighter);
}

.po-text-color-orange {
  color: var(--color-orange);
}

.po-text-color-blue-light {
  color: var(--color-subtitle-dark);
}

.po-text-color-blue {
  color: var(--color-text-blue);
}

.po-text-color-primary {
  color: var(--color-text-blue);
}

.po-text-color-warning {
  color: var(--color-warning);
}

.po-text-color-danger {
  color: var(--color-danger);
}

.po-text-color-white {
  color: var(--color-white);
}

.po-font-subtitle {
  font-family: var(--font-family-extra-light);
}

.po-bg-grey {
  background: var(--color-toolbar-background-color-toolbar);
}

.scroll-y {
  overflow-y: auto;
}

.po-link {
  text-decoration: underline;
  user-select: none;
  cursor: pointer;
}

.po-link:active {
  color: var(--color-button-color-link-pressed);
}

.cdk-drag-preview {
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.touchable {
  position: relative;
  user-select: none;
  cursor: pointer;
}

.touchable:hover:active {
  opacity: 0.4;
}

.po-page-content {
  overflow-x: hidden;
}

.po-text-color-red {
  color: var(--color-danger);
}

.po-wrapper .po-page-header {
  padding-bottom: 0.9375rem;
}

.po-input-group {
  display: flex;
  align-items: flex-end;
}
.po-input-group po-input {
  flex-basis: 70%;
}
.po-input-group po-input .po-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.po-input-group po-select {
  flex-basis: 30%;
}
.po-input-group po-select .po-select-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-top: 9px;
  padding-bottom: 9px;
}

.barcode-container {
  position: relative;
}
.barcode-container .po-button {
  position: absolute;
  right: 8px;
  bottom: 10px;
}
.barcode-container .po-button:focus {
  outline: none;
}

.group-button {
  display: flex;
  align-items: flex-end;
}
.group-button div:nth-child(1) {
  padding-right: 0;
}
.group-button input {
  border-right: none !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.group-button button {
  cursor: pointer;
  font-size: 1.3em;
  margin-bottom: 8px;
  border-radius: 0 5px 5px 0;
  font-family: var(--font-family-extra-light);
  width: auto !important;
}
.group-button button:focus {
  outline: none;
}
.group-button button span.po-icon {
  font-size: 1.5em;
}

.group-fields .po-select-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0px;
}
@media screen and (max-width: 767px) {
  .group-fields .po-select-button {
    border-right-width: 1px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
.group-fields .po-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
@media screen and (max-width: 767px) {
  .group-fields .po-input {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

.po-select-mobile {
  width: auto;
}

@media screen and (max-width: 1366px) {
  .po-select-button {
    height: 14px !important;
    line-height: 14px !important;
  }
}
.app-version {
  position: fixed;
  right: 10px;
  bottom: 10px;
  color: var(--color-secondary-dark-60);
}

.po-table-column-label.po-color-13 {
  background-color: var(--color-grey);
}

div + .po-container {
  --padding: 16px;
  --border-width: unset;
  --border-color: unset;
  --background: var(--background-color-container);
  box-shadow: 0 1px 4px 0 var(--color-black-alpha-30);
}

ni-collapsible-widget.collapsible-custom {
  --collapsible-widget-header-padding: 0;
  --collapsible-widget-body-padding: 0;
  --collapsible-widget-body-margin: 0;
}
ni-collapsible-widget.collapsible-custom * {
  min-width: 0;
}

.po-container-custom {
  --padding: 16px;
  --border-width: unset;
  --border-color: unset;
  --background: var(--background-color-container);
  box-shadow: 0 1px 4px 0 var(--color-black-alpha-30);
}

.display-block {
  display: block;
}

.po-container-custom-2 {
  --border-width: unset;
  --border-color: unset;
  --background: #ffffff;
}
.po-container-custom-2 .po-icon.po-icon-arrow-down:before {
  color: var(--color-text-blue);
}
.po-container-custom-2 .collapsible-widget {
  padding: 0.5rem 1rem;
}
// @TODO: More generic name for --color-header-gradient

:root {
  // Shared
  --color-orange: #ed9c2e;
  --color-orange-hover: #df7e11;
  --color-unavailable: #433b65;
  --color-text: #000000;
  --color-text-light: #273d52;
  --color-text-lighter: #939ea8;
  --color-white: #ffffff;
  --color-text-secondary: var(--color-white);
  --color-body-background-color: #f6f7f9;
  --color-background-menu: #1b1d23;
  --color-bg-light-grey: #f9f9f9;
  --color-text-dark-grey: #2d3434;
  --color-text-darker: #333333;
  --color-bg-dark-grey-light: #22262f;
  --color-background-light-blue: #eff6f9;
  --color-text-light-blue: #ceebf2;
  --color-success-lighter: #b1e4d6;
  --color-subtitle-dark: #a1cbd5;
  --color-subtitle-lighter: #97a0aa;
  --color-bluish-grey: #273d52;
  --color-green: #00b28e;
  --color-blue: #1cadee;
  --color-dark-blue: #193550;
  --color-water-green: #29b6c5;
  --color-red: #eb4646;
  --color-border: #cccccc;
  --color-background-sub-item: #f4f7f8;
  --color-menu-filter-background-color: #fff;

  --color-success-dark-20: #00997a;
  --color-success-dark-40: #007f65;
  --color-warning-light-20: #ffd897;
  --color-warning-dark-20: #e78f27;
  --color-warning-dark-40: #dd8318;
  --color-light-grey: #eceeee;
  --color-grey: #9da7a9;
  --color-default-texts: #707070;
  --color-dark-grey: #717678;
  --color-greenish-grey: #4a5c60;
  --color-ni-icon: #202330;
  // Fonts
  --font-family: var(--font-family-theme);
  --font-family-bold: var(--font-family-theme-bold);
  --font-family-extra-light: var(--font-family-theme-extra-light);
  // Header
  --color-header-gradient-start: var(--color-bluish-grey);
  --color-header-gradient-end: #4f708a;
  --color-header-input-border: #3e5a72;
  // Button
  --button-background: var(--color-body-background-color);
  --button-background-focus: var(--color-neutral-light-80);
  --button-background-active: var(--color-neutral-light-60);
  --button-color: var(--color-header-gradient-start);
  --button-selected-background: var(--color-primary-dark-40);
  --button-selected-color: var(--color-white);
  // Tabs
  --tab-header-color: var(--color-header-gradient-start);
  --tab-header-bg-color: var(--color-neutral-light-98);
  --tab-bg-color: var(--color-white);
  // Badge
  --badge-dark-red: #9a2f2f;
  --badge-blue: var(--color-blue);
  --badge-red: #ff756e;
  --badge-pink: #ff6969;
  --badge-primary: var(--color-text-blue);
  --badge-success: var(--color-success);
  --badge-warning: var(--color-warning);
  --badge-danger: var(--color-danger);
  --badge-unavailable: var(--color-unavailable);
  --badge-table: var(--card-off);
  --badge-table-text: var(--color-header-gradient-start);
  --badge-dark-info: var(--color-bluish-grey);
  --badge-grey: var(--color-grey);
  // Item Card
  --card-off: #f5f5f5;
  --card-active: var(--color-tertiary);
  --card-primary: var(--color-text-blue);
  --card-success: var(--color-success);
  --card-warning: var(--color-warning);
  --card-danger: var(--color-danger);
  --card-unavailable: var(--color-unavailable);
  /// Kds Card
  --kds-card-title-background-color: var(--color-bg-light-grey);
  --kds-card-background-color: var(--color-white);
  --kds-card-header-progress-background-color: rgba(0, 0, 0, 0.2);
  --color-toolbar-color-title: #273d52;
  --color-pipe-background-color: #e5e5e5;
  // Collapsible Widget
  --collapsible-widget-header-color: #2fbecc;
  --collapsible-widget-header-focus-color: var(--color-secondary-dark-60);
  --collapsible-widget-divider-color: #dfebf0;
  // Transaction History
  --footer-bg: var(--color-neutral-light-98);
  --footer-text: var(--color-header-gradient-start);
  --footer-value: #4bbdc9;
  // Popover dark
  --popover-dark-title: var(--collapsible-widget-header-color);
  --popover-dark-subtitle: var(--color-subtitle-dark);
  --color-checkbox-group-border-input: var(--color-water-green);
  --color-checkbox-menu-label: #c4cbd2;
  --color-border-checkbox-menu-item: #3d566d;
  --backgroud-color-seleted-item: #1e3449;
  // Progress bar
  --progress-bar-background: var(--card-off);
  --progress-bar-color-default: var(--collapsible-widget-header-color);
  --progress-bar-box-shadow-10: rgba(0, 0, 0, 0.1);
  --progress-bar-box-shadow-15: rgba(0, 0, 0, 0.15);
  --progress-bar-box-shadow-20: rgba(0, 0, 0, 0.2);
  // Technical detail progress bar
  --technical-detail-progress-bar: rgba(47, 190, 204, 0.2);
  // Percentual badge color
  --percentual-badge-color-light-green: #50e3c2;
  // Table Bagde Popover
  --table-badge-popover-backgroud: var(--color-greenish-grey);
  //amount received
  --amount-received-light-blue: #2fbecc;
  --amount-received-grey: #2d3434;
  // Slider
  --slider-background: var(--color-success);
  --slider-background-light: var(--color-success-lighter);
  //Average Occupancy
  --average-occupancy-red: #ff756e;
  --average-occupancy-green: #50e3c2;
  --average-occupancy-black: #333333;
  //inventory-view
  --inventory-view-red: #e4463e;
  --inventory-view-yellow: #f5a623;
  --inventory-view-black: #1a1a1a;
  // Consumption card list tab
  --consumption-card-tab-background: #fbfbfb;
  --consumption-card-search-bottom-line: #eaecef;
  --consumption-card-search-back: #c3c3c3;
  // Progress circle
  --progress-circle-background: #cfe4e9;
  --progress-circle-color-default: var(--collapsible-widget-header-color);
  //Sales amount
  --sales-amount-yellow: #f5a623;
  //company
  --divider-fiscal: #d5e2e4;
  --send-file: var(--color-water-green);
  --select-file-background: #cfe4e9;
  --select-file-color: #929da0;
  --color-divider: #dfebf0;
  --start-upload: var(--color-white);
  --divider-fiscal-bar: var(--divider-fiscal);
  --send-file-button: var(--send-file);
  --select-file-button: var(--select-file-background);
  --select-file-button-color: var(--select-file-color);
  --color-welcome-login: #d69a47;
  --welcome-login: #d85959;
  --color-bg-company-card-header: var(--color-bg-light-grey);
  //Button Group
  --ni-color-button-group-border-selected: var(--color-text-blue);
  --ni-color-button-group-background-selected: var(--color-text-blue);
  --ni-color-button-group-border-hover: #097b98;
  --ni-color-button-group-background-hover: #097b98;
  // Customer List
  --customer-list-border-color: #b6bdbf1f;
  --customer-list-background-color: #f3f3f3;
  // modal discount
  --user-name-color: #1d2426;
  // Lite cards scrolling
  --shadow-degrade-transparent-0: rgba(246, 247, 249, 0);
  --shadow-degrade-transparent-0-8: rgba(246, 247, 249, 0.8);
  --button-shadow: var(--progress-bar-box-shadow-10);
  // Room details modal
  --background-content: #f6f6f6;
  // Custom Scroll bar
  --scrollbar-thumb-color: rgba(0, 0, 0, 0.3);
  // Keyboard PIN
  --keyboard-pin-ripple-active: #ededed;
  //Button Search Pos
  --dashboard-select-button-font-color: var(--color-dark-grey);
  //Report Table Color
  --report-table: #d3d3d3;
  --color-table-box-shadow-header: var(--color-tertiary);
  --color-table-color-header: var(--color-text-dark);
  //Customer List Types
  --customer-list-sparse: #4a5055;
  --customer-list-guest: var(--color-dark-blue);
  --customer-list-event: #697e5c;
  --customer-list-card: #514762;
  --customer-list-person: #f39022;
  --background-color-toolbar-menu-right: var(--color-white);
  --background-color-ni-popover: var(--color-white);
  --color-icon-table-header: var(--color-text-blue);
  --color-text-empty-content: #bdbdbd;
  --background-color-pagination: var(--color-white);
  --color-text-pagination-active: var(--color-white);
  --background-color-pagination-active: var(--color-tertiary);
  --color-text-pagination: #273d52;
  --color-pagination-icon-disabled: #97a0aa;
  --background-kitchen-order-list-bottom: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 92%
  );
  //Customer Input Kds Login
  --customer-border-color-input: #b6bdbf;
  --switch-color-label: var(--color-greenish-grey);
  //Container
  --background-color-container: var(--color-white);

  //Preview-Image
  --color-background-preview-image: #f4f7f8;

  //Calendar
  --color-background-calendar: var(--color-dark-grey);
  --color-background-calendar-disabled: #b6bdbf;

  // Total Value Background
  --color-background-blue-fixed: #193550;

  // Scrollbar
  --color-scrollbar: rgba(31, 61, 82, 0.5);

  //Change password
  --title-change-password: #4a5c60;
  --color-background-divider: #c8c8c8;

  --background-color-text-more-items: var(--color-white);
}

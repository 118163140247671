po-dropdown {
  .po-dropdown:focus,
  .po-dropdown-button:focus {
    outline: none;
  }

  &.no-border {
    .po-dropdown-button {
      border: none;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &.small {
    .po-dropdown-button {
      padding-top: 4px;
      padding-bottom: 4px;
      height: auto;

      .po-dropdown-icon {
        top: 4px;
      }
    }
  }
}

.po-menu-filter {
  background-color: var(--color-menu-filter-background-color);
  color: var(--color-text-light);
}

.po-menu-sub-items {
  background-color: var(--color-menu-background-color-sub-items);
}

// popover //
.profile .po-popover {
  min-width: 350px;
}

// container //

po-container.no-overflow .po-container {
  overflow-y: unset;
}

// page //

.po-page {
  background: white;
}

.po-wrapper .po-page-header {
  border-bottom: 1px solid #d3d3d3;
}

.po-page-header {
  padding: 16px 70px 16px 70px;
}

.po-wrapper .po-page .po-page-content {
  padding: 20px 70px;
}

// font //

.po-page-header-title {
  font-family: var(--font-family-theme), sans-serif;
}

// input //

.po-select-button {
  height: 14px;
  line-height: 14px;
  font-size: 14px;
}

.po-input,
.po-rich-text-body,
.po-select-button {
  box-shadow: inset 0 1px 8px 0 #fff;
}

.exercise-container .po-input {
  padding: 0 2px;
}

.plan-tab .po-field-title {
  font-weight: 700;
}

.po-input {
  height: 35px;
}

.input-time-width input {
  width: 4rem;
}

//button

.btn-substitution-primary .po-button {
  --color-button-border: var(--color-button-substitution-primary);
  --color-button-background-color: var(--color-button-substitution-primary);
  --color-button-color: var(--color-white);

  &:hover {
    --color-button-border-hover: var(--color-button-substitution-primary);
    --color-button-background-color-hover: var(
      --color-button-substitution-primary
    );
    --color-button-color-hover: var(--color-white);
    opacity: 0.5;
  }
}

.btn-substitution-secondary .po-button {
  --color-button-border: var(--color-button-substitution-secondary);
  --color-button-background-color: var(--color-button-substitution-secondary);
  --color-button-color: var(--color-white);

  &:hover {
    --color-button-border-hover: var(--color-button-substitution-secondary);
    --color-button-background-color-hover: var(
      --color-button-substitution-secondary
    );
    --color-button-color-hover: var(--color-white);
    opacity: 0.5;
  }
}

//input time

.input-time {
  margin: 8px 0;

  &[readonly] {
    background: var(--color-input-border-text-disabled);
  }
}

//input of sum calories

.input-sum {
  padding: 0;

  .po-field-container {
    background: var(--color-background-gray);
    padding: 4px 4px;
    border-radius: 2px;
    border: 0.1px solid #9a9a9a;

    .po-field-container-content {
      padding: 0;

      .po-input {
        background: var(--color-background-gray);
        box-shadow: none;
        border: var(--color-background-gray);
        padding: 0;
        color: black;
        font-weight: 600;
        font-size: 1.1rem;

        &:focus {
          border: none !important;
          outline-width: 0;
        }
      }
    }
  }
}

//input time

.input-time {
  margin: 8px 0;

  &[readonly] {
    background: var(--color-input-border-text-disabled);
  }
}

//input of sum calories

.input-sum {
  padding: 0;

  .po-field-container {
    background: var(--color-background-gray);
    padding: 4px 4px;
    border-radius: 2px;
    border: 0.1px solid #9a9a9a;

    .po-field-container-content {
      padding: 0;

      .po-input {
        background: var(--color-background-gray);
        box-shadow: none;
        border: var(--color-background-gray);
        padding: 0;
        color: black;
        font-weight: 600;
        font-size: 1.1rem;

        &:focus {
          border: none !important;
          outline-width: 0;
        }
      }
    }
  }
}

// menu

.po-menu-item {
  min-height: 4.5em;
}

//table

.custom-table-white {
  min-height: unset;


  & .po-table .po-table-master-detail thead tr th,
  .po-table thead tr th {
    --background-color-headline: var(--color-white);
  }

  & .po-table .po-table-master-detail thead tr th,
  .po-table thead tr th {
    padding: 8px 16px;
  }

  .po-table[p-spacing=medium] tbody tr td:not(.po-table-column-detail) {
    padding-block: 0.35rem;
  }
}

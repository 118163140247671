.container-badge-flag {
  display: inline-flex;
  align-items: center;
  background-color: #FFB400;
  justify-content: center;
  border-radius: 4px;
  padding: 4px 10px;
  width: auto;

  .container-badge-flag-body {
    border-radius: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;

    i {
      height: 25px;
      width: 25px;
      font-family: Titillium Web Bold, serif;
      font-weight: bold;
      margin-right: 2px;
    }

    span {
      font-family: var(--font-family-bold);
      font-size: 13px;
      color: #fff;
      line-height: 100%;
    }
  }
}

@import 'variables';
@import 'flex';
@import 'grid';
@import 'override_portinari';
@import 'new-table';
@import 'table';
@import 'inputs';
@import 'button';
@import 'new-button-group';
@import 'popover';
@import 'badge-flag-inlist';

// app specific styles
body {
  height: auto;
  min-height: 100%;
  background-color: var(--color-body-background-color);
  color: var(--color-body-text-color);
}

a,
button,
img {
  touch-action: manipulation;
}

po-select {
  &.po-select-clear {
    .po-select-button,
    .po-select-button:hover,
    .po-select-button:active,
    .po-select-button:focus {
      background-color: transparent !important;
      border: none !important;
      color: var(--color-primary-dark-40) !important;
      box-shadow: none;
      text-align: right;
    }

    .po-select-button {
      font-size: 0.9em;
    }

    .po-select-content {
      margin-top: 0;
    }

    .po-field-container-content {
      max-width: 250px;
      padding: 0;
    }
  }
}

.po-text-right {
  text-align: right;
}

.po-text-xlarge-light {
  font-size: 20px;
  font-family: 'NunitoSans-ExtraLight', sans-serif;
  font-weight: lighter;
  text-align: center;
}

.po-w-100 {
  width: 100%;
}

.po-h-100 {
  height: 100%;
}

.bold {
  font-weight: bold;
}

.regular {
  font-weight: 400;
}

.po-text-color-light {
  color: var(--color-text-light);
}

.po-text-color-lighter {
  color: var(--color-text-lighter);
}

.po-text-color-orange {
  color: var(--color-orange);
}

.po-text-color-blue-light {
  color: var(--color-subtitle-dark);
}

.po-text-color-blue {
  color: var(--color-text-blue);
}

.po-text-color-primary {
  color: var(--color-text-blue);
}

.po-text-color-warning {
  color: var(--color-warning);
}

.po-text-color-danger {
  color: var(--color-danger);
}

.po-text-color-white {
  color: var(--color-white);
}

.po-font-subtitle {
  font-family: var(--font-family-extra-light);
}

.po-bg-grey {
  background: var(--color-toolbar-background-color-toolbar);
}

.scroll-y {
  overflow-y: auto;
}

.po-link {
  text-decoration: underline;
  user-select: none;
  cursor: pointer;
}

.po-link:active {
  color: var(--color-button-color-link-pressed);
}

.cdk-drag-preview {
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.touchable {
  position: relative;
  user-select: none;
  cursor: pointer;
}

.touchable:hover:active {
  opacity: 0.4;
}

.po-page-content {
  overflow-x: hidden;
}

.po-text-color-red {
  color: var(--color-danger);
}

.po-wrapper .po-page-header {
  padding-bottom: 0.9375rem;
}

.po-input-group {
  display: flex;
  align-items: flex-end;

  po-input {
    flex-basis: 70%;

    .po-input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  po-select {
    flex-basis: 30%;

    .po-select-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-top: 9px;
      padding-bottom: 9px;
    }
  }
}

// Barcode Scanner
.barcode-container {
  position: relative;

  .po-button {
    position: absolute;
    right: 8px;
    bottom: 10px;

    &:focus {
      outline: none;
    }
  }
}

// ./Barcode Scanner

// Button and field together
.group-button {
  display: flex;
  align-items: flex-end;

  div:nth-child(1) {
    padding-right: 0;
  }

  input {
    border-right: none !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button {
    cursor: pointer;
    font-size: 1.3em;
    margin-bottom: 8px;
    border-radius: 0 5px 5px 0;
    font-family: var(--font-family-extra-light);
    width: auto !important;

    &:focus {
      outline: none;
    }

    span.po-icon {
      font-size: 1.5em;
    }
  }
}

// ./Button and field together

// Select and field together
.group-fields {
  .po-select-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0px;

    @media screen and (max-width: 767px) {
      border-right-width: 1px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  .po-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    @media screen and (max-width: 767px) {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
}

// Select and field together

.po-select-mobile {
  width: auto;
}

@media screen and (max-width: 1366px) {
  .po-select-button {
    height: 14px !important;
    line-height: 14px !important;
  }
}

.app-version {
  position: fixed;
  right: 10px;
  bottom: 10px;
  color: var(--color-secondary-dark-60);
}

.po-table-column-label.po-color-13 {
  background-color: var(--color-grey);
}

div + .po-container {
  --padding: 16px;
  --border-width: unset;
  --border-color: unset;
  --background: var(--background-color-container);

  box-shadow: 0 1px 4px 0 var(--color-black-alpha-30);
}


ni-collapsible-widget.collapsible-custom {
  --collapsible-widget-header-padding: 0;
  --collapsible-widget-body-padding: 0;
  --collapsible-widget-body-margin: 0;

  * {
    min-width: 0;
  }
}

.po-container-custom {
  --padding: 16px;
  --border-width: unset;
  --border-color: unset;
  --background: var(--background-color-container);
  box-shadow: 0 1px 4px 0 var(--color-black-alpha-30);
}

.display-block {
  display: block;
}

.po-container-custom-2 {
  --border-width: unset;
  --border-color: unset;
  --background: #ffffff;

  .po-icon.po-icon-arrow-down:before {
    color: var(--color-text-blue);
  }

  .collapsible-widget {
    padding: 0.5rem 1rem;
  }
}

.table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: gray;
}

.po-table:has(.po-table-column-detail-toggle),  {
  background: var(--background-color-headline);

}
.po-table tbody tr td {
  @media screen and (max-width: 1366px) {
    font-family: var(--font-family-theme), sans-serif;
    font-size: 12px;
    line-height: 16px;
  }

  font-size: 12px;
}

.po-table thead tr th {
  font-size: 12px;
  line-height: 14px;
}


.po-table .po-table-column-cell {
  &:has(po-button) {
    white-space: unset;
  }

  & {
    white-space: initial;
  }
}

.po-table-header-flex {
  gap: 5px;
}

.po-table-header-icon-ascending, .po-table-header-icon-descending, .po-table-header-icon-unselected {
  width: 15px;
}

po-table .po-table-single-action {
  text-decoration: unset !important;
}

div + .new-table,
po-table + .new-table {
  width: 100%;

  --font-size-default: 12px;
  --font-family: var(--font-family-bold);
  --background-color-headline: #f1f1f1;

  .po-table tbody tr td {
    border-bottom: 1px solid var(--color-divider);
  }

  .po-table {
    color: #000000;
    font-size: 12px;

    thead {
      position: sticky;
      top: 0;
      z-index: 20;
    }

    .po-table-header {
      background: #f1f1f1;
      border-bottom: none;
      height: 65px;

      th:nth-child(1),
      th:nth-child(2) {
        font-size: 18px;
        line-height: 100%;
      }
    }

    .po-table-column-cell {
      color: var(--color-text-gray);
      font-family: var(--font-family-theme);
    }

    .po-table-row {
      height: 60px;
    }
  }

  .po-table-main-container {
    max-height: 470px;
    overflow: auto;
  }
}

.table-v2 {
  width: 100%;

  .table-column-item-blue {
    color: #ffffff;
    font-weight: bold;
    background: transparent linear-gradient(180deg, #126df7 0%, #1c95ee 100%);
    border-radius: 20px;
    padding: 2px 12px;
    opacity: 1;
    display: inline-flex;
    text-align: center;
  }

  .po-table {
    color: #000000;

    thead {
      position: sticky;
      top: 0;
    }

    .po-table-header {
      border-bottom: none;
      height: 45px;
      font-size: 12px;
    }

    .po-table-row {
      height: 60px;
    }
  }

  .po-table-main-container {
    //height: 488px;
    overflow: auto;
  }
}


.table-hide-header {
  min-height: unset;
  .po-table-header {
    display: none;
  }
}


.icon-right-button {
  border-radius: 100%;
  background: #126df7;
  color: #fff;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-wrap-unset {
  flex-wrap: unset;
}

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: Titillium Web;
  src: url("../fonts/TitilliumWeb-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Titillium Web Semibold;
  src: url("../fonts/TitilliumWeb-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: Titillium Web Bold;
  src: url("../fonts/TitilliumWeb-Bold.ttf") format("truetype");
}
h1,
h2,
h3,
h4,
h5,
h6 {
  --font-family-heading: Titillium Web, sans-serif;
}

.title-very-large-heavy {
  font-size: 1.875rem; /* 30px / 16px = 1.5rem */
}

.title-very-large {
  font-size: 1.5rem; /* 24px / 16px = 1.5rem */
}

.title-large {
  font-size: 1.25rem; /* 20px / 16px = 1.25rem */
}

.title-intermediate {
  font-size: 1.125rem; /* 18px / 16px = 1.25rem */
}

.title-medium {
  font-size: 1rem; /* 16px / 16px = 1rem */
}

.title-small {
  font-size: 0.875rem; /* 14px / 16px = 0.875rem */
}

.title-very-small {
  font-size: 0.75rem; /* 12px / 16px = 0.75rem */
}

.title-very-medium-small {
  font-size: 0.625rem; /* 10px / 16px = 0.625rem */
}

.title-very-big-small {
  font-size: 0.5625rem; /* 9px / 16px = 0.75rem */
}

.po-label,
.po-field-title {
  font-size: 14px;
}

.po-font-text-bold {
  color: #1a1a1a;
}

.line-height-100 {
  line-height: 100%;
}

:root {
  --background-filter-home: #1a2230;
  --background-map-filter-active: #e08100;
  --color-primary: var(--color-text-blue);
  --color-action-default: var(--color-text-blue);
  --color-white: #fff;
  --color-text-light: #000000;
  --color-text-gray: #707070;
  --color-container-title: #4a5055;
  --color-horizontal-bar: #193550;
  --background-map: #48b5d4;
  --color-text-blue: #147afc;
  --color-hover-menu: #07e9ff;
  --color-background-menu: linear-gradient(
      180deg,
      rgba(0, 109, 247, 1) 0%,
      rgba(28, 149, 238, 1) 40%
  );
  --colors-pendency-item: #ff174c;
  --color-divider: #eceeee;
  --color-menu-background-color-sub-items: var(--color-background-menu);
  --color-background-login: #eceeee;
  --color-button-background-color-primary: var(--color-text-blue);
  --toolbar-background: var(--color-text-blue);
  --color-input-border-text-focusable: var(--color-text-blue);
  --color-input-border-text: var(--color-text-blue);
  --color-multiselect-color-text-disabled: var(--color-text-blue);
  --color-calendar-title: var(--color-text-blue);
  --color-loading-icon-color-primary: var(--color-text-blue);
  --color-calendar-background-color-box-background-active: var(--color-text-blue);
  --color-calendar-background-color-box-background-selected: var(--color-text-blue);
  --color-calendar-arrow: var(--color-text-blue);
  --color-calendar-color-box-foreground-today: var(--color-text-blue);
  --color-button-border-primary: var(--color-text-blue);
  --color-button-background-color-primary-hover: rgba(20, 122, 252, 0.5);
  --color-button-border-primary-hover: rgba(20, 122, 252, 0.5);
  --color-button-background-color-primary-pressed: rgba(20, 122, 252, 0.5);
  --color-button-border-primary-pressed: rgba(20, 122, 252, 0.5);
  --color-table-color-single-action-hover: rgba(20, 122, 252, 0.5);
  --color-switch-background-color-container-on: var(--color-text-blue);
  --color-table-box-shadow-header: var(--color-text-blue);
  --color-field-icon-color: var(--color-text-blue);
  --color-select-color-primary: var(--color-text-blue);
  --color-select-border-button-hover: var(--color-text-blue);
  --color-breadcrumb-color-item: var(--color-text-blue);
  --color-button-border-hover: rgba(20, 122, 252, 0.5);
  --color-button-color-hover: rgba(20, 122, 252, 0.5);
  --color-radio-group-background-color-active: var(--color-text-blue);
  --color-table-color-actions: var(--color-text-blue);
  --color-table-background-color-selected: var(--color-text-blue);
  --color-table-border-selected-checkbox: var(--color-text-blue);
  --color-select-background-color-selected: var(--color-text-blue);
  --color-disclaimer-background-color-label: var(--color-text-blue);
  --color-disclaimer-background-color-remove: var(--color-text-blue);
  --color-select-border-button-focus: var(--color-text-blue);
  --color-checkbox-background-color-active: var(--color-text-blue);
  --color-multiselect-background-color-checkbox-active: var(--color-text-blue);
  --color-checkbox-group-border-input: var(--color-text-blue);
  --color-multiselect-border-field-focus: var(--color-text-blue);
  --color-multiselect-color-primary: var(--color-text-blue);
  --color-divider-label-color: var(--color-text-blue);
  --color-checkbox-border-input-active: var(--color-text-blue);
  --color-checkbox-group-background-color-active: var(--color-text-blue);
  --color-divider-line-color: var(--color-text-blue);
  --color-dropdown-button-border-color-hover: var(--color-text-blue);
  --color-dropdown-button-color-hover: var(--color-text-blue);
  --color-dropdown-button-color: var(--color-text-blue);
  --color-dropdown-button-border-color: var(--color-text-blue);
  --color-popup-color-default: var(--color-text-blue);
  --color-checkbox-group-border-input-active: var(--color-text-blue);
  --color-accordion-header-color-primary: var(--color-text-blue);
  --color-tab-button-box-shadow-active: var(--color-text-blue);
  --color-tab-button-color: var(--color-text-blue);
  --color-upload-drag-drop-area-border-primary: var(--color-text-blue);
  --color-textarea-border-focusable: var(--color-text-blue);
  --color-combo-color-primary: var(--color-text-blue);
  --color-combo-background-color-selected-primary: var(--color-text-blue);
  --color-upload-drag-drop-overlay-text: var(--color-text-blue);
  --color-progress-color-info-icon: var(--color-text-blue);
  --color-upload-drag-drop-overlay-icon: var(--color-text-blue);
  --color-button-color-link-hover: var(--color-text-blue);
  --color-upload-drag-drop-area-select-files-text: var(--color-text-blue);
  --color-stepper-circle-active: var(--color-text-blue);
  --color-button-border: var(--color-text-blue);
  --color-stepper-circle-done: rgb(20, 122, 252, 0.4);
  --color-menu-color-menu-responsive: var(--color-text-blue);
  --color-modal-color-header-close-button: var(--color-text-blue);
  --color-accordion-header-color-hover: var(--color-text-blue);
  --color-tab-dropdown-background-color-tab-dropdown-button-active: var(--color-text-blue);
  --color-select-button-focus: var(--color-text-blue);
  --color-text-step-default: #bfbfbf;
  --color-button-color-link-hover-white: rgba(255, 255, 255, 0.7);
  --color-table-column-manager-close-button-color: var(--color-text-blue);
  --collapsible-widget-header-color: var(--color-black);
  --color-button-color: var(--color-text-blue);
  --color-menu-color-menu-item-text: #fff;
  --color-background-button-table: #06badb;
  --color-background-gray: #dedede;
  --color-background-button-table-hover: rgb(6, 186, 219, 0.8);
  --color-menu-color-menu-sub-item-text: #fff;
  --color-menu-border-top-logo: transparent;
  --font-family-theme: Titillium Web;
  --font-family-theme-semibold: Titillium Web Semibold;
  --font-family-theme-bold: Titillium Web Bold;
  --color-12: #bfbfbf;
  --color-header-chats: #dedede;
  --color-button-group-background-selected: var(--color-text-blue);
  --color-button-group-background-color-neutral-hover: var(--color-text-blue);
  --color-button-group-color-neutral-hover: var(--color-white);
  --color-button-group-border-selected: var(--color-text-blue);
  --color-button-group-background-color-neutral-pressed: var(--color-text-blue);
  --color-button-group-border-neutral-pressed: var(--color-text-blue);
  --color-header-conversation: #f4f4f4;
  --color-message-professional: #c0faff;
  --color-border-input-message: #00adee;
  --color-box-icon-message: #07ecff;
  --color-background-chat: #fbfbfb;
  --color-card-conversation-status: #e9f3ff;
  --color-text-body-message: #555555;
  --color-background-scheduled-today: #2181fb;
  --color-background-scheduled-for-the-today: #bababa;
  --color-button-substitution-primary: #06badb;
  --color-button-substitution-secondary: #db8906;
}

@media print {
  body * {
    visibility: hidden;
  }
  .po-menu,
  po-menu,
  ni-toolbar,
  .ni-toolbar {
    display: none;
  }
  #printable,
  #printable * {
    visibility: visible;
  }
  #printable,
  .po-modal-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.pointer-events-block:hover {
  cursor: not-allowed !important;
}
.pointer-events-block a {
  pointer-events: none;
}

.list-container {
  width: 100%;
  display: inline-block !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  color: var(--color-default-texts);
}

td,
.po-info-value,
.po-info-label {
  color: var(--color-text-gray);
}

.font-color-default {
  color: var(--color-text-blue);
}

.position-relative {
  position: relative;
}

.pendency-day {
  color: var(--colors-pendency-item);
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.description-pill {
  font-family: var(--font-family-theme-bold);
  font-size: 1rem;
}

.font-title-bold {
  font-family: var(--font-family-theme-bold);
  color: var(--color-text-gray);
  overflow-wrap: break-word;
}

.padding-to-tree {
  padding: 50px 0 5px 5px;
}

.block-size {
  block-size: auto;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.padding-side-small {
  padding: 0 8px;
}

.padding-side-medium {
  padding: 0 16px;
}

.po-container.image-preview img {
  width: 100%;
}

.image-preview {
  padding: 15px;
  height: 400px;
  width: auto;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  box-shadow: 0 1px 5px rgb(0, 0, 0);
}

.image-preview-no-shadow {
  height: 400px;
  width: auto;
  min-width: 150px;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
}
.image-preview-no-shadow img {
  max-width: 100%;
  max-height: 100%;
}

.pdf-preview-no-shadow {
  height: 600px;
  width: auto;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
}
.pdf-preview-no-shadow img {
  max-width: 100%;
  max-height: 100%;
}

.title-preview {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: bold;
}

.width-preview {
  width: 100%;
}

.reason-container {
  cursor: pointer;
}
.reason-container .reason-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 1.5s ease-in;
  max-height: 2em;
}
.reason-container .overflow {
  white-space: normal;
  overflow: visible;
  transition: 1.5s ease-in;
  max-height: 100vh;
}

.page-white {
  background-color: #fff;
  flex-wrap: wrap;
  display: flex;
}

.gray {
  color: var(--color-text-gray);
}

.black {
  color: var(--color-black);
}

.center-webkit {
  text-align: -webkit-center;
  text-align: -moz-center;
}

.container-status {
  color: #fff;
  text-align: center;
  margin-top: auto;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.container-status.InProgress {
  background: linear-gradient(#2ad6f5, #0cb2ef);
}
.container-status.Pending {
  background: linear-gradient(#fcb618, #e8671f);
}
.container-status.Expired {
  background: linear-gradient(#fa7543, #d20816);
}
.container-status.Concluded {
  background: linear-gradient(#126df7, #1c95ee);
}
.container-status.badge {
  padding: 4px;
  border-radius: 10px;
  line-height: 100%;
}

.no-margin {
  margin: 0 !important;
}

.small-gap {
  gap: 0.5rem;
}

.medium-gap {
  gap: 2rem;
}

timesicon .p-dropdown-clear-icon {
  display: flex;
  align-items: center;
}

.vertical-align-text-top {
  vertical-align: text-top;
}

.action-card-list span:hover {
  padding: 0 6px;
  font-weight: bolder;
  transition: 0.2s;
}

.gap-025 {
  gap: 0.25rem;
}

.gap-05 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-1-5 {
  gap: 1.5rem;
}

.gap-2 {
  gap: 2rem;
}

.gap-3 {
  gap: 3rem;
}

.po-popover {
  min-height: unset;
}

.custom.po-popover {
  min-width: 0;
  min-height: 0;
}
.custom.po-popover.dark,
.custom.po-popover.dark .po-popover-arrow {
  --background-color-ni-popover: var(--color-greenish-grey);
  background-color: var(--color-greenish-grey);
  color: var(--color-text-secondary);
}
.custom.po-popover .po-popover-content {
  padding: 8px 12px;
}

.dynamic-table-with-page-default po-page-dynamic-search po-page-list po-page .po-page {
  margin-left: 0 !important;
  width: 100% !important;
}

.po-table-header-column-manager-button {
  padding: 0;
}

po-select .po-field-title {
  font-family: var(--font-family-theme), sans-serif;
}

/* Text */
.po-text-link {
  color: var(--color-text-blue);
  font-weight: 600;
  cursor: pointer;
}

.po-font-text-big-large {
  font-size: 46px;
  color: var(--color-text-gray);
}

.po-font-text-large {
  font-size: 24px;
  color: var(--color-text-gray);
}

.po-font-text-small {
  font-size: 18px;
}

.po-fonte-text-smaller {
  font-size: 16px;
  color: var(--color-text-gray);
}

.po-field-title {
  font-family: var(--font-family-theme), sans-serif;
  color: var(--color-text-gray);
}
.po-field-title.w-auto {
  width: auto;
}

.goal-title {
  font-size: 34px;
  color: var(--color-text-gray);
}

/* Select */
.po-select-item {
  font-size: 14px;
}

/* Table */
po-table {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.po-row.po-table-footer-show-more {
  margin-top: auto;
}

.po-color-03 {
  background-color: var(--color-text-blue);
  fill: var(--color-text-blue);
}

po-table .po-table-single-action {
  --color-table-color-actions: var(--color-white);
  box-shadow: 0 4px 8px 0 var(--color-black-alpha-15);
  background-color: var(--color-button-background-color-primary);
  border: 1px solid var(--color-button-border-primary);
  height: 1.5rem;
  border-radius: 3px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: unset;
}
po-table .po-table-single-action.po-table-action-disabled {
  opacity: 0.3;
}
po-table .po-table-single-action:hover {
  --color-table-color-single-action-hover: var(
    --color-button-color-primary-hover
  );
  background-color: var(--color-button-background-color-primary-hover);
  border: 1px solid var(--color-button-border-primary-hover);
}

po-modal .po-modal-title.po-text-ellipsis {
  white-space: normal;
}

po-table .po-button {
  font-family: var(--font-family-theme), sans-serif;
  font-size: 12px;
  line-height: 21px;
  height: 24px;
  padding: 0 8px;
  font-weight: 600;
}
po-table .button-table {
  --color-button-background-color-primary: var(
    --color-background-button-table
  );
  --color-button-color: var(--color-white);
  --color-button-border-primary: var(--color-background-button-table);
  --color-button-background-color-primary-hover: var(
    --color-background-button-table-hover
  );
  --color-button-border-primary-hover: var(
    --color-background-button-table-hover
  );
  --color-button-color-primary-hover: var(--color-white);
}
po-table .po-table-column-label,
po-table .po-table-subtitle-circle {
  text-transform: none;
}
po-table .po-table .po-icon, po-table .po-table .po-icon-three-dots, po-table .po-table .po-icon-external-attendance, po-table .po-table .po-icon-custom-warning, po-table .po-table .po-icon-occupational, po-table .po-table .po-icon-attestations, po-table .po-table .po-icon-micoff, po-table .po-table .po-icon-micon, po-table .po-table .po-icon-onboarding, po-table .po-table .po-icon-chat-v2, po-table .po-table .po-icon-plans, po-table .po-table .po-icon-heart, po-table .po-table .po-icon-register, po-table .po-table .po-icon-panel {
  height: auto;
}

.po-table ni-icon {
  display: flex;
  height: 1.2em;
  contain: unset;
}

.po-table .po-table-row-template-container {
  padding: 0;
}

.po-stepper-container {
  pointer-events: none;
}

.po-popover-content .po-info-container-content {
  padding: 0;
}

.po-info-container-content {
  padding: 0;
}

.po-info-custom .po-info-label {
  font-size: 12px;
  color: var(--color-text-gray);
  font-family: "Titillium Web";
}
.po-info-custom .po-info-value {
  color: #000;
  font-size: 18px;
  font-weight: bold;
}

.po-menu-item {
  font-family: var(--font-family-theme), sans-serif;
  font-weight: unset;
  font-size: 14px;
}

.room-has-participant {
  color: var(--color-11);
}

.room-empty {
  color: var(--color-12);
}

.p-dropdown-custom p-overlay {
  z-index: 10;
  position: fixed;
}
.p-dropdown-custom .p-dropdown .p-dropdown-clear-icon {
  top: 13px;
}
.p-dropdown-custom .p-dropdown-empty-message {
  padding: 8px 16px;
}
.p-dropdown-custom .p-dropdown-filter {
  height: 40px;
  padding: 0 16px;
}
.p-dropdown-custom searchicon.p-icon-wrapper {
  position: absolute;
  right: 0;
  top: -15px;
  bottom: 0;
}
.p-dropdown-custom .p-dropdown {
  height: 44px !important;
  width: 100%;
  border: 1px solid #b6bdbf;
  border-radius: 3px;
}
.p-dropdown-custom .p-dropdown:hover {
  color: var(--color-select-color-primary);
}
.p-dropdown-custom .p-dropdown .p-dropdown-label {
  font-size: 14px;
  color: var(--color-select-color-primary);
  font-family: var(--font-family-theme), sans-serif;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.p-dropdown-custom .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  font-size: 15px !important;
  color: var(--color-select-color-primary);
}
@media screen and (max-width: 1366px) {
  .p-dropdown-custom .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
    font-size: 11px !important;
  }
}
.p-dropdown-custom .p-dropdown .p-dropdown-panel .p-dropdown-item {
  white-space: normal;
  font-size: 14px;
  color: var(--color-select-color-primary);
  font-family: var(--font-family-theme), sans-serif;
  line-height: 24px;
  cursor: pointer;
  display: block;
  padding: 10px 16px;
  text-align: left;
  word-wrap: break-word;
}
.p-dropdown-custom .p-dropdown .p-dropdown-panel .p-dropdown-item:hover {
  background-color: #f3fafc !important;
  color: var(--color-select-color-primary) !important;
}

.advanced-search-disclaimer .po-disclaimer-group {
  border: none;
  text-align: end;
  padding: 8px 0px;
}

.health-team .po-container {
  height: calc(100% - 10px) !important;
  overflow: hidden;
}

.font-color-default {
  font-weight: bold;
}

.no-progress .po-upload-progress-container {
  display: none;
}

.modal-with-combo .po-modal-body {
  overflow-x: visible;
  overflow-y: visible;
}

.divider {
  margin: 10px 0 10px 0;
}

/****************************************************************************
 *
 * Menu icons
 *
 ****************************************************************************/
.po-icon, .po-icon-three-dots, .po-icon-external-attendance, .po-icon-custom-warning, .po-icon-occupational, .po-icon-attestations, .po-icon-micoff, .po-icon-micon, .po-icon-onboarding, .po-icon-chat-v2, .po-icon-plans, .po-icon-heart, .po-icon-register, .po-icon-panel {
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: auto;
}
.po-icon:not(.po-menu-group-icon), .po-icon-three-dots:not(.po-menu-group-icon), .po-icon-external-attendance:not(.po-menu-group-icon), .po-icon-custom-warning:not(.po-menu-group-icon), .po-icon-occupational:not(.po-menu-group-icon), .po-icon-attestations:not(.po-menu-group-icon), .po-icon-micoff:not(.po-menu-group-icon), .po-icon-micon:not(.po-menu-group-icon), .po-icon-onboarding:not(.po-menu-group-icon), .po-icon-chat-v2:not(.po-menu-group-icon), .po-icon-plans:not(.po-menu-group-icon), .po-icon-heart:not(.po-menu-group-icon), .po-icon-register:not(.po-menu-group-icon), .po-icon-panel:not(.po-menu-group-icon) {
  margin: 0 auto;
}
.po-icon-panel {
  background-image: url("../icons/panel.svg");
}
.po-icon-register {
  background-image: url("../icons/register.svg");
}
.po-icon-heart {
  background-image: url("../icons/heart.svg");
}
.po-icon-plans {
  background-image: url("../icons/plans.svg");
}
.po-icon-chat-v2 {
  background-image: url("../icons/chat.svg");
}
.po-icon-onboarding {
  background-image: url("../icons/onboarding-white.svg");
}
.po-icon-micon {
  background-image: url("../icons/micon.svg");
}
.po-icon-micoff {
  background-image: url("../icons/micoff.svg");
}
.po-icon-attestations {
  background-image: url("../icons/attestations.svg");
}
.po-icon-occupational {
  background-image: url("../icons/occupational.svg");
}
.po-icon-custom-warning {
  background-image: url("../icons/warning.svg");
  margin-left: -4px;
}
.po-icon-external-attendance {
  background-image: url("../icons/external-attendance.svg");
}
.po-icon-three-dots {
  background-image: url("../icons/three-dots.svg");
}

.po-menu .po-icon, .po-menu .po-icon-panel, .po-menu .po-icon-register, .po-menu .po-icon-heart, .po-menu .po-icon-plans, .po-menu .po-icon-chat-v2, .po-menu .po-icon-onboarding, .po-menu .po-icon-micon, .po-menu .po-icon-micoff, .po-menu .po-icon-attestations, .po-menu .po-icon-occupational, .po-menu .po-icon-custom-warning, .po-menu .po-icon-external-attendance, .po-menu .po-icon-three-dots {
  width: 20px;
  height: 20px;
}

.custom-icon-add-remove {
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  border-radius: 111px;
  border: 2px solid var(--color-text-blue);
  align-items: center;
  justify-content: center;
  font-size: 27px;
  color: var(--color-text-blue);
}
.custom-icon-add-remove:hover {
  opacity: 0.7;
}

.icon-button-container {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  color: var(--color-text-blue);
  cursor: pointer;
}
.icon-button-container:hover {
  opacity: 0.5;
}
.icon-button-container .icon-button {
  margin-left: 5px;
  background-color: var(--color-text-blue);
  color: var(--color-white);
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 14px;
  font-size: 16px;
}

.cdk-drag-preview.active {
  cursor: move;
  align-items: center;
  display: flex;
}
.cdk-drag-preview.box {
  cursor: move;
  display: flex;
  align-items: center;
}

/* The Modal (background) */
.custom-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.custom-modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.custom-modal-content,
#custom-caption {
  -webkit-animation-name: custom-zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: custom-zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes custom-zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
/* The Close Button */
.custom-close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.custom-close:hover,
.custom-close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .custom-modal-content {
    width: 100%;
  }
}
.border-active .po-input {
  border: 2px solid #2181FB !important;
}
.new-button-group .po-button-group-container {
  po-button {
    .po-button {
      padding: 0 35px;
      font-size: 18px;
      height: 44px;
      font-family: 'Titillium Web', serif;
    }

    &:first-child {
      .po-button {
        border-top-left-radius: 53px;
        border-bottom-left-radius: 53px;
      }
    }

    &:last-child {
      .po-button {
        border-top-right-radius: 53px;
        border-bottom-right-radius: 53px;
      }
    }
  }

  .po-button-group.po-button-group-button-selected .po-button {
    font-family: 'Titillium Web Bold', serif;
  }
}

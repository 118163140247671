:root {
  --background-filter-home: #1a2230;
  --background-map-filter-active: #e08100;
  --color-primary: var(--color-text-blue);
  --color-action-default: var(--color-text-blue);
  --color-white: #fff;
  --color-text-light: #000000;
  --color-text-gray: #707070;
  --color-container-title: #4a5055;
  --color-horizontal-bar: #193550;
  --background-map: #48b5d4;
  //TODO: Ficar de olho nos links
  //--color-button-color-link: #bfbfbf;
  --color-text-blue: #147afc;
  --color-hover-menu: #07e9ff;

  --color-background-menu: linear-gradient(
      180deg,
      rgba(0, 109, 247, 1) 0%,
      rgba(28, 149, 238, 1) 40%
  );
  --colors-pendency-item: #ff174c;
  --color-divider: #eceeee;
  --color-menu-background-color-sub-items: var(--color-background-menu);
  --color-background-login: #eceeee;
  --color-button-background-color-primary: var(--color-text-blue);
  --toolbar-background: var(--color-text-blue);
  --color-input-border-text-focusable: var(--color-text-blue);
  --color-input-border-text: var(--color-text-blue);
  --color-multiselect-color-text-disabled: var(--color-text-blue);
  --color-calendar-title: var(--color-text-blue);
  --color-loading-icon-color-primary: var(--color-text-blue);
  --color-calendar-background-color-box-background-active: var(--color-text-blue);
  --color-calendar-background-color-box-background-selected: var(--color-text-blue);
  --color-calendar-arrow: var(--color-text-blue);
  --color-calendar-color-box-foreground-today: var(--color-text-blue);
  --color-button-border-primary: var(--color-text-blue);
  --color-button-background-color-primary-hover: rgba(20, 122, 252, 0.5);
  --color-button-border-primary-hover: rgba(20, 122, 252, 0.5);
  --color-button-background-color-primary-pressed: rgba(20, 122, 252, 0.5);
  --color-button-border-primary-pressed: rgba(20, 122, 252, 0.5);
  --color-table-color-single-action-hover: rgba(20, 122, 252, 0.5);
  --color-switch-background-color-container-on: var(--color-text-blue);
  --color-table-box-shadow-header: var(--color-text-blue);
  --color-field-icon-color: var(--color-text-blue);
  --color-select-color-primary: var(--color-text-blue);
  --color-select-border-button-hover: var(--color-text-blue);
  --color-breadcrumb-color-item: var(--color-text-blue);
  --color-button-border-hover: rgba(20, 122, 252, 0.5);
  --color-button-color-hover: rgba(20, 122, 252, 0.5);
  --color-radio-group-background-color-active: var(--color-text-blue);
  --color-table-color-actions: var(--color-text-blue);
  --color-table-background-color-selected: var(--color-text-blue);
  --color-table-border-selected-checkbox: var(--color-text-blue);
  --color-select-background-color-selected: var(--color-text-blue);
  --color-disclaimer-background-color-label: var(--color-text-blue);
  --color-disclaimer-background-color-remove: var(--color-text-blue);
  --color-select-border-button-focus: var(--color-text-blue);
  --color-checkbox-background-color-active: var(--color-text-blue);
  --color-multiselect-background-color-checkbox-active: var(--color-text-blue);
  --color-checkbox-group-border-input: var(--color-text-blue);
  --color-multiselect-border-field-focus: var(--color-text-blue);
  --color-multiselect-color-primary: var(--color-text-blue);
  --color-divider-label-color: var(--color-text-blue);
  --color-checkbox-border-input-active: var(--color-text-blue);
  --color-checkbox-group-background-color-active: var(--color-text-blue);
  --color-divider-line-color: var(--color-text-blue);
  --color-dropdown-button-border-color-hover: var(--color-text-blue);
  --color-dropdown-button-color-hover: var(--color-text-blue);
  --color-dropdown-button-color: var(--color-text-blue);
  --color-dropdown-button-border-color: var(--color-text-blue);
  --color-popup-color-default: var(--color-text-blue);
  --color-checkbox-group-border-input-active: var(--color-text-blue);
  --color-accordion-header-color-primary: var(--color-text-blue);
  --color-tab-button-box-shadow-active: var(--color-text-blue);
  --color-tab-button-color: var(--color-text-blue);
  --color-upload-drag-drop-area-border-primary: var(--color-text-blue);
  --color-textarea-border-focusable: var(--color-text-blue);
  --color-combo-color-primary: var(--color-text-blue);
  --color-combo-background-color-selected-primary: var(--color-text-blue);
  --color-upload-drag-drop-overlay-text: var(--color-text-blue);
  --color-progress-color-info-icon: var(--color-text-blue);
  --color-upload-drag-drop-overlay-icon: var(--color-text-blue);
  --color-button-color-link-hover: var(--color-text-blue);
  --color-upload-drag-drop-area-select-files-text: var(--color-text-blue);
  --color-stepper-circle-active: var(--color-text-blue);
  --color-button-border: var(--color-text-blue);
  --color-stepper-circle-done: rgb(20, 122, 252, 0.4);
  --color-menu-color-menu-responsive: var(--color-text-blue);
  --color-modal-color-header-close-button: var(--color-text-blue);
  --color-accordion-header-color-hover: var(--color-text-blue);
  --color-tab-dropdown-background-color-tab-dropdown-button-active: var(--color-text-blue);
  --color-select-button-focus: var(--color-text-blue);
  --color-text-step-default: #bfbfbf;
  --color-button-color-link-hover-white: rgba(255, 255, 255, 0.7);
  --color-table-column-manager-close-button-color: var(--color-text-blue);
  --collapsible-widget-header-color: var(--color-black);
  --color-button-color: var(--color-text-blue);
  --color-menu-color-menu-item-text: #fff;
  --color-background-button-table: #06badb;
  --color-background-gray: #dedede;
  --color-background-button-table-hover: rgb(6, 186, 219, 0.8);
  --color-menu-color-menu-sub-item-text: #fff;
  --color-menu-border-top-logo: transparent;
  --font-family-theme: Titillium Web;
  --font-family-theme-semibold: Titillium Web Semibold;
  --font-family-theme-bold: Titillium Web Bold;
  --color-12: #bfbfbf;
  --color-header-chats: #dedede;
  --color-button-group-background-selected: var(--color-text-blue);
  --color-button-group-background-color-neutral-hover: var(--color-text-blue);
  --color-button-group-color-neutral-hover: var(--color-white);
  --color-button-group-border-selected: var(--color-text-blue);
  --color-button-group-background-color-neutral-pressed: var(--color-text-blue);
  --color-button-group-border-neutral-pressed: var(--color-text-blue);
  --color-header-conversation: #f4f4f4;
  --color-message-professional: #c0faff;
  --color-border-input-message: #00adee;
  --color-box-icon-message: #07ecff;
  --color-background-chat: #fbfbfb;
  --color-card-conversation-status: #e9f3ff;
  --color-text-body-message: #555555;
  --color-background-scheduled-today: #2181fb;
  --color-background-scheduled-for-the-today: #bababa;
  --color-button-substitution-primary: #06badb;
  --color-button-substitution-secondary: #db8906;
}

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: Titillium Web;
  src: url('../fonts/TitilliumWeb-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Titillium Web Semibold;
  src: url('../fonts/TitilliumWeb-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: Titillium Web Bold;
  src: url('../fonts/TitilliumWeb-Bold.ttf') format('truetype');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  --font-family-heading: Titillium Web, sans-serif;
}

.title-very-large-heavy {
  font-size: 1.875rem; /* 30px / 16px = 1.5rem */
}

.title-very-large {
  font-size: 1.5rem; /* 24px / 16px = 1.5rem */
}

.title-large {
  font-size: 1.25rem; /* 20px / 16px = 1.25rem */
}

.title-intermediate {
  font-size: 1.125rem; /* 18px / 16px = 1.25rem */
}

.title-medium {
  font-size: 1rem; /* 16px / 16px = 1rem */
}

.title-small {
  font-size: 0.875rem; /* 14px / 16px = 0.875rem */
}

.title-very-small {
  font-size: 0.75rem; /* 12px / 16px = 0.75rem */
}

.title-very-medium-small {
  font-size: 0.625rem; /* 10px / 16px = 0.625rem */
}

.title-very-big-small {
  font-size: 0.5625rem; /* 9px / 16px = 0.75rem */
}

// -------------------------------------

.po-label,
.po-field-title {
  font-size: 14px;
}

.po-font-text-bold {
  color: #1a1a1a;
}

.line-height-100 {
  line-height: 100%;
}

.line-height-10 {
  line-height: 10px;
}

@media screen and (max-width: 480px) {
  .po-visible-sm,
  [class*=' po-visible-sm-'],
  [class*='po-visible-sm-'] {
    display: block !important;
  }
  .po-hidden-sm {
    display: none !important;
  }
}

.po-rich-text-body:focus-visible {
  outline: unset;
  border: 2px solid #b6bdbf;
}

.po-menu-item-link:focus > .po-menu-item:not(.po-menu-item-no-data),
.po-menu-item:not(.po-menu-item-no-data):is(:focus, :focus-visible) {
  outline: unset;
}

.po-input,
.po-multiselect-input, select, .p-dropdown {
  border: 1px solid #b6bdbf;

  &:hover {
    background-color: unset;
    border: 1px solid #b6bdbf;
  }

  &:focus {
    border: 2px solid #b6bdbf;
    outline: unset;
  }
}

.po-multiselect-input {
  &:hover:not(.po-multiselect-input-disabled) {
    background-color: unset;
    border: 2px solid #b6bdbf;
  }
}

.po-checkbox-outline {
  &:is(:focus, :focus-visible) {
    outline: unset;
  }
}

.po-button-link:hover {
  background-color: unset;
}

.po-button:enabled:active, .po-button:focus, .po-button:focus-visible, select:focus {
  outline: unset;
}

select:hover, .po-textarea:hover {
  background-color: unset;
}

//select
select {
  padding: 8px;
}

.po-combo-input-focus,
.po-combo-input:focus {
  outline: unset;
}

.po-combo-container-content
span:hover.po-field-icon.po-icon.po-icon-arrow-down.po-icon-input,
.po-combo-input-focus:hover,
.po-combo-input:focus:hover,
.po-combo-input:hover:not(:disabled),
.po-field-icon-container-right
.po-field-icon.po-icon.po-combo-background-arrow-up.po-icon-arrow-up {
  background-color: transparent;
}

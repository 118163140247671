.po-button[p-kind=secondary]:hover {
  background-color: unset;
}

.to-refresh {
  cursor: pointer;
  border: 0;
  background-color: initial;

  &:focus {
    animation: rotation 1s ease-in-out 0s;
  }

}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.po-popover {
  min-height: unset;
}

.custom.po-popover {
  min-width: 0;
  min-height: 0;

  &.dark {
    &,
    .po-popover-arrow {
      --background-color-ni-popover: var(--color-greenish-grey);
      background-color: var(--color-greenish-grey);
      color: var(--color-text-secondary);
    }
  }

  .po-popover-content {
    padding: 8px 12px;
  }
}

.dynamic-table-with-page-default
  po-page-dynamic-search
  po-page-list
  po-page
  .po-page {
  margin-left: 0 !important;
  width: 100% !important;
}

.po-table-header-column-manager-button {
  padding: 0;
}

po-select .po-field-title {
  font-family: var(--font-family-theme), sans-serif;
}

/* Text */

.po-text-link {
  color: var(--color-text-blue);
  font-weight: 600;
  cursor: pointer;
}

.po-font-text-big-large {
  font-size: 46px;
  color: var(--color-text-gray);
}

.po-font-text-large {
  font-size: 24px;
  color: var(--color-text-gray);
}

.po-font-text-small {
  font-size: 18px;
}

.po-fonte-text-smaller {
  font-size: 16px;
  color: var(--color-text-gray);
}

.po-field-title {
  font-family: var(--font-family-theme), sans-serif;
  color: var(--color-text-gray);;

  &.w-auto {
    width: auto;
  }
}

.goal-title {
  font-size: 34px;
  color: var(--color-text-gray);;
}

/* Select */

.po-select-item {
  font-size: 14px;
}

/* Table */

po-table {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  position: relative;

  //TODO: Checar depois o porque está quebrando as tabelas do plano de jornada
  //flex-wrap: wrap;
}

.po-row.po-table-footer-show-more {
  margin-top: auto;
}

$color-table-color-actions: var(--color-white);
$color-table-color-single-action-hover: var(
  --color-button-color-link-hover-white
);

.po-color-03 {
  background-color: var(--color-text-blue);
  fill: var(--color-text-blue);
}

po-table .po-table-single-action {
  --color-table-color-actions: var(--color-white);
  box-shadow: 0 4px 8px 0 var(--color-black-alpha-15);
  background-color: var(--color-button-background-color-primary);
  border: 1px solid var(--color-button-border-primary);
  height: 1.5rem;
  border-radius: 3px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: unset;

  &.po-table-action-disabled {
    opacity: 0.3;
  }

  &:hover {
    --color-table-color-single-action-hover: var(
      --color-button-color-primary-hover
    );
    background-color: var(--color-button-background-color-primary-hover);
    border: 1px solid var(--color-button-border-primary-hover);
  }
}

po-modal .po-modal-title.po-text-ellipsis {
  white-space: normal;
}

po-table {
  .po-button {
    font-family: var(--font-family-theme), sans-serif;
    font-size: 12px;
    line-height: 21px;
    height: 24px;
    padding: 0 8px;
    font-weight: 600;
  }

  .button-table {
    --color-button-background-color-primary: var(
      --color-background-button-table
    );
    --color-button-color: var(--color-white);
    --color-button-border-primary: var(--color-background-button-table);

    --color-button-background-color-primary-hover: var(
      --color-background-button-table-hover
    );
    --color-button-border-primary-hover: var(
      --color-background-button-table-hover
    );
    --color-button-color-primary-hover: var(--color-white);
  }

  .po-table-column-label,
  .po-table-subtitle-circle {
    text-transform: none;
  }

  //TODO: Ficar de olho
  .po-table-group-row
    .po-table-row
    .po-table-column:nth-last-child(2)
    .notranslate[ng-reflect-ng-switch='columnTemplate'] {
    //float: right;
  }

  .po-table .po-icon {
    height: auto;
  }
}

// Icon //

.po-table ni-icon {
  display: flex;
  height: 1.2em;
  contain: unset;
}

//table
.po-table .po-table-row-template-container {
  padding: 0;
}

// Stepper //

.po-stepper-container {
  pointer-events: none;
}

// po-info {

.po-popover-content .po-info-container-content {
  padding: 0;
}

.po-info-container-content {
  padding: 0;
}

.po-info-custom {
  .po-info-label {
    font-size: 12px;
    color: var(--color-text-gray);;
    font-family: 'Titillium Web';
  }

  .po-info-value {
    color: #000;
    font-size: 18px;
    font-weight: bold;
  }
}

// po-menu {

.po-menu-item {
  font-family: var(--font-family-theme), sans-serif;
  font-weight: unset;
  font-size: 14px;
}

// room Color {

.room-has-participant {
  color: var(--color-11);
}

.room-empty {
  color: var(--color-12);
}

.p-dropdown-custom {
  p-overlay {
    z-index: 10;
    position: fixed;
  }

  .p-dropdown .p-dropdown-clear-icon {
    top: 13px;
  }

  .p-dropdown-empty-message {
    padding: 8px 16px;
  }

  .p-dropdown-filter {
    height: 40px;
    padding: 0 16px;
  }

  searchicon.p-icon-wrapper {
    position: absolute;
    right: 0;
    top: -15px;
    bottom: 0;
  }

  .p-dropdown {
    &:hover {
      color: var(--color-select-color-primary);
    }

    height: 44px !important;
    width: 100%;
    border: 1px solid #b6bdbf;
    border-radius: 3px;

    .p-dropdown-label {
      font-size: 14px;
      color: var(--color-select-color-primary);
      font-family: var(--font-family-theme), sans-serif;
      display: flex;
      align-items: center;
      padding-left: 16px;
    }

    .p-dropdown-trigger {
      .p-dropdown-trigger-icon {
        font-size: 15px !important;
        @media screen and (max-width: 1366px) {
          font-size: 11px !important;
        }
        color: var(--color-select-color-primary);
      }
    }

    .p-dropdown-panel {
      .p-dropdown-item {
        white-space: normal;
        font-size: 14px;
        color: var(--color-select-color-primary);
        font-family: var(--font-family-theme), sans-serif;
        line-height: 24px;
        cursor: pointer;
        display: block;
        padding: 10px 16px;
        text-align: left;
        word-wrap: break-word;

        &:hover {
          background-color: #f3fafc !important;
          color: var(--color-select-color-primary) !important;
        }
      }
    }
  }
}

.advanced-search-disclaimer {
  .po-disclaimer-group {
    border: none;
    text-align: end;
    padding: 8px 0px;
  }
}

.health-team {
  .po-container {
    height: calc(100% - 10px) !important;
    overflow: hidden;
  }
}

.font-color-default {
  font-weight: bold;
}

//po-upload

.no-progress .po-upload-progress-container {
  display: none;
}

//po-modal

.modal-with-combo .po-modal-body {
  overflow-x: visible;
  overflow-y: visible;
}

//divider
.divider {
  margin: 10px 0 10px 0;
}

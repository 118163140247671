@media print {
  body * {
    visibility: hidden;
  }

  .po-menu,
  po-menu,
  ni-toolbar,
  .ni-toolbar {
    display: none;
  }

  #printable,
  #printable * {
    visibility: visible;
  }

  #printable,
  .po-modal-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-block {
  &:hover {
    cursor: not-allowed !important;
  }

  a {
    pointer-events: none;
  }
}

.list-container {
  width: 100%;
  display: inline-block !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  color: var(--color-default-texts);
}

td,
.po-info-value,
.po-info-label {
  color: var(--color-text-gray);
}

.font-color-default {
  color: var(--color-text-blue);
}

.position-relative {
  position: relative;
}

.pendency-day {
  color: var(--colors-pendency-item);
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.description-pill {
  font-family: var(--font-family-theme-bold);
  font-size: 1rem;
}

.font-title-bold {
  font-family: var(--font-family-theme-bold);
  color: var(--color-text-gray);
  overflow-wrap: break-word;
}

.padding-to-tree {
  padding: 50px 0 5px 5px;
}

.block-size {
  block-size: auto;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.padding-side-small {
  padding: 0 8px;
}

.padding-side-medium {
  padding: 0 16px;
}

.po-container.image-preview img {
  width: 100%;
}

.image-preview {
  padding: 15px;
  height: 400px;
  width: auto;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  box-shadow: 0 1px 5px rgb(0, 0, 0.1);
}

.image-preview-no-shadow {
  height: 400px;
  width: auto;
  min-width: 150px;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.pdf-preview-no-shadow {
  height: 600px;
  width: auto;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.title-preview {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: bold;
}

.width-preview {
  width: 100%;
}

.reason-container {
  cursor: pointer;

  .reason-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 1.5s ease-in;
    max-height: 2em;
  }

  .overflow {
    white-space: normal;
    overflow: visible;
    transition: 1.5s ease-in;
    max-height: 100vh;
  }
}

//page

.page-white {
  background-color: #fff;
  flex-wrap: wrap;
  display: flex;
}

//color

.gray {
  color: var(--color-text-gray);
}

.blue {
  color: var(--color-text-blue);
}

.black {
  color: var(--color-black);
}

// center

.center-webkit {
  text-align: -webkit-center;
  text-align: -moz-center;
}

//badge status

.container-status {
  color: #fff;
  text-align: center;
  margin-top: auto;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  &.InProgress {
    background: linear-gradient(#2ad6f5, #0cb2ef);
  }

  &.Pending {
    background: linear-gradient(#fcb618, #e8671f);
  }

  &.Expired {
    background: linear-gradient(#fa7543, #d20816);
  }

  &.Concluded {
    background: linear-gradient(#126df7, #1c95ee);
  }

  &.badge {
    padding: 4px;
    border-radius: 10px;
    line-height: 100%;
  }
}

.no-margin {
  margin: 0 !important;
}

.small-gap {
  gap: 0.5rem;
}

.medium-gap {
  gap: 2rem;
}

timesicon .p-dropdown-clear-icon {
  display: flex;
  align-items: center;
}

.vertical-align-text-top {
  vertical-align: text-top;
}

//card list actions

.action-card-list {
  & span:hover {
    padding: 0 6px;
    font-weight: bolder;
    transition: 0.2s;
  }
}

.gap-025 {
  gap: 0.25rem;
}

.gap-05 {
  gap: 0.5rem;
}

.gap-075 {
  gap: 0.75rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-1-5 {
  gap: 1.5rem;
}

.gap-2 {
  gap: 2rem;
}

.gap-3 {
  gap: 3rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: rgba(0, 0, 0, 0.2);

  &:hover {
    opacity: 0.5;
  }
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #2196f3;
}

.letter-p-icon {
  border-radius: 100%;
  width: 22px;
  height: 22px;
  display: flex;
  border: 2px solid #2181FB;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #2181FB;
  flex-shrink: 0;
}

// opacity
.opacity-05 {
  opacity: 0.5;
}

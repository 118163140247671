/****************************************************************************
 *
 * Menu icons
 *
 ****************************************************************************/



.po-icon {
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: auto;

  &:not(.po-menu-group-icon) {
    margin: 0 auto;
  }

  &-panel {
    @extend .po-icon;
    background-image: url('../icons/panel.svg');
  }

  &-register {
    @extend .po-icon;
    background-image: url('../icons/register.svg');
  }

  &-heart {
    @extend .po-icon;
    background-image: url('../icons/heart.svg');
  }

  &-plans {
    @extend .po-icon;
    background-image: url('../icons/plans.svg');
  }

  &-chat-v2 {
    @extend .po-icon;
    background-image: url('../icons/chat.svg');
  }

  &-onboarding {
    @extend .po-icon;
    background-image: url('../icons/onboarding-white.svg');
  }

  &-micon {
    @extend .po-icon;
    background-image: url('../icons/micon.svg');
  }

  &-micoff {
    @extend .po-icon;
    background-image: url('../icons/micoff.svg');
  }

  &-attestations {
    @extend .po-icon;
    background-image: url('../icons/attestations.svg');
  }

  &-occupational {
    @extend .po-icon;
    background-image: url('../icons/occupational.svg');
  }

  &-custom-warning {
    @extend .po-icon;
    background-image: url('../icons/warning.svg');
    margin-left: -4px;
  }

  &-external-attendance {
    @extend .po-icon;
    background-image: url('../icons/external-attendance.svg');
  }

  &-three-dots {
    @extend .po-icon;
    background-image: url('../icons/three-dots.svg');
  }
}

.po-menu .po-icon {
  width: 20px;
  height: 20px;
}

.custom-icon-add-remove {
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  border-radius: 111px;
  border: 2px solid var(--color-text-blue);
  align-items: center;
  justify-content: center;
  font-size: 27px;
  color: var(--color-text-blue);

  &:hover {
    opacity: 0.7;
  }
}

.icon-button-container {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  color: var(--color-text-blue);
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  .icon-button {
    margin-left: 5px;
    background-color: var(--color-text-blue);
    color: var(--color-white);
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    padding: 14px;
    font-size: 16px;
  }
}

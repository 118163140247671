.cdk-drag-preview {
  &.active {
    cursor: move;
    align-items: center;
    display: flex;
  }

  &.box {
    cursor: move;
    display: flex;
    align-items: center;
  }
}

// Flex variation
//
// Custom styles for additional flex alignment options.

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-wrap-unset {
  flex-wrap: unset;
}
